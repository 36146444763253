import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import Edit from '@/components/Reveal/Icons/Edit';
import { PDFEditor } from '@hiresweet/hiresweet-lib';

import styles from './EditButton.module.less';

type ProfileAttachment = {
  id: string;
  name: string;
  type: string;
  author: {
    firstname: string;
    lastname: string;
    email: string;
  };
  creationDate: string;
  file: {
    extension?: string;
    content?: string;
    url?: string;
  };
};

type Props = {
  attachment: ProfileAttachment;
  profileId: string;
};

const EditButton = ({ attachment, profileId }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  if (!attachment) {
    return '';
  }

  return (
    <>
      <Button className='edit-button' onClick={() => setModalOpen(true)}>
        <Edit className='edit-icon' />
      </Button>
      <PDFEditor
        className={styles.editorModal}
        profileId={profileId}
        attachment={attachment}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default EditButton;
