import React from 'react';
import classNames from 'classnames';
import _ from 'underscore';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { CustomProfileSource } from '@/graphql/hooks/clients/useClientCustomProfileSources';
import styles from './CustomProfileSourcesTable.module.less';
import { getTranslatedText } from '../../../../../common/index';

interface Props {
  customSources: CustomProfileSource[] | undefined;
  onEdit: (customSource: CustomProfileSource) => void;
  onDelete: (customSourceId: string) => void;
}

const CustomProfileSourcesSettingsTable: React.FC<Props> = ({
  customSources,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();

  if (_.isEmpty(customSources) || !customSources?.length) {
    return null;
  }

  return (
    <Table basic className={styles.table}>
      <Table.Header className={styles.header}>
        <Table.Row className={styles.headerRow}>
          <Table.HeaderCell className={styles.th}>
            <span>
              {t('reveal.parameters.customProfileSourcesSettings.table.name')}
            </span>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.th}>
            <span>
              {t(
                'reveal.parameters.customProfileSourcesSettings.table.isApplicationOption',
              )}
            </span>
          </Table.HeaderCell>
          <Table.HeaderCell collapsing className={styles.th} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(customSources, (customSource) => (
          <Table.Row key={customSource.id} className={styles.row}>
            <Table.Cell textAlign='left' className={styles.name}>
              <span>{getTranslatedText(customSource.title)}</span>
            </Table.Cell>
            <Table.Cell textAlign='left' className={styles.subtype}>
              <span>
                {customSource.isApplicationSourceOption
                  ? t(`common.yes`)
                  : t('common.no')}
              </span>
            </Table.Cell>

            <Table.Cell className={styles.actions}>
              <i
                className={classNames('ri-pencil-line ri-lg', styles.icon)}
                onClick={() => onEdit(customSource)}
              />
              <i
                className={classNames('ri-delete-bin-line ri-lg', styles.icon)}
                onClick={() => onDelete(customSource.id)}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default CustomProfileSourcesSettingsTable;
