import { TranslatableText } from '@/types/text';
import { gql, useMutation } from '@apollo/client';

const ADD_CLIENT_CUSTOM_PROFILE_SOURCE = gql`
  mutation AddClientCustomProfileSource(
    $customProfileSource: AddClientCustomProfileSourceInput!
  ) {
    addClientCustomProfileSource(customProfileSource: $customProfileSource) {
      id
      customProfileSources {
        id
        title {
          default
          fr
          en
        }
        isApplicationSourceOption
      }
    }
  }
`;

interface AddClientCustomProfileSourceVariables {
  customProfileSource: {
    id: string;
    title: TranslatableText;
    isApplicationSourceOption: boolean;
  };
}

export interface ClientCustomProfileSourceResults {
  client: {
    id: string;
    customProfileSources: {
      id: string;
      title: TranslatableText;
      isApplicationSourceOption: boolean;
    }[];
  };
}

const useAddClientCustomProfileSource = () => {
  return useMutation<
    ClientCustomProfileSourceResults,
    AddClientCustomProfileSourceVariables
  >(ADD_CLIENT_CUSTOM_PROFILE_SOURCE);
};

export default useAddClientCustomProfileSource;
