import { TranslatableText } from '@/types/text';
import { gql, useMutation } from '@apollo/client';

const DELETE_CLIENT_CUSTOM_PROFILE_SOURCE = gql`
  mutation DeleteClientCustomProfileSource($id: String!) {
    deleteClientCustomProfileSource(id: $id) {
      id
      customProfileSources {
        id
        title {
          default
          fr
          en
        }
        isApplicationSourceOption
      }
    }
  }
`;

interface DeleteClientCustomProfileSourceVariables {
  id: string;
}

interface ClientCustomProfileSourceResults {
  client: {
    id: string;
    customProfileSources: {
      id: string;
      title: TranslatableText;
      isApplicationSourceOption: boolean;
    }[];
  };
}

const useDeleteClientCustomProfileSource = () => {
  return useMutation<
    ClientCustomProfileSourceResults,
    DeleteClientCustomProfileSourceVariables
  >(DELETE_CLIENT_CUSTOM_PROFILE_SOURCE);
};

export default useDeleteClientCustomProfileSource;
