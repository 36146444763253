import { TranslatableText } from '@/types/text';
import { gql, useMutation } from '@apollo/client';

const ADD_CLIENT_DOCUMENT_TYPE = gql`
  mutation AddClientDocumentType($documentType: AddClientDocumentTypeInput) {
    addClientDocumentType(documentType: $documentType) {
      id
      clientDocumentTypes {
        key
        value {
          default
          fr
          en
        }
        category
      }
    }
  }
`;

interface AddClientDocumentTypeVariables {
  documentType: {
    key: string;
    value: TranslatableText;
    category: string;
  };
}

export interface ClientDocumentTypeResults {
  client: {
    documentType: {
      key: string;
      value: {
        default: string;
        fr: string;
        en: string;
      };
      category: string;
    }[];
  };
}

const useAddClientDocumentType = () => {
  return useMutation<ClientDocumentTypeResults, AddClientDocumentTypeVariables>(
    ADD_CLIENT_DOCUMENT_TYPE,
  );
};

export default useAddClientDocumentType;
