import _ from 'underscore';

import { deepIndex } from '@/common/utils/record';
import { getTranslatedText } from '@/common/helpers/translatableText';
import type {
  ProfileOverviewCardItemContent,
  ProfileOverviewCardCustomFieldItemContent,
} from '@/common/helpers/profileOverviewCard';
import type { SearchPoolProfile } from '@/types/searchPoolProfile';
import type { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import type { SupportedLanguage } from '@/common/utils/i18n';

export const contentToStrings =
  (context: ItemContentResolutionContext) =>
  (content: ProfileOverviewCardItemContent): string[] => {
    const { profile } = context;
    if (content.type === 'builtin-string') {
      const string = deepIndex(profile, content.path);
      return string ? [string] : [];
    }
    if (content.type === 'builtin-date') {
      const dateValue = deepIndex(profile, content.path);
      if (!dateValue) {
        return [];
      }
      try {
        if (typeof dateValue === 'string' && /^[0-9]*$/.test(dateValue)) {
          return [new Date(+dateValue).toISOString()];
        }
        return [new Date(dateValue).toISOString()];
      } catch (_e) {
        return [];
      }
    }
    if (content.type === 'composite') {
      return content.elements.flatMap(contentToStrings(context));
    }
    if (content.type === 'customField') {
      return customFieldContentToStrings(context)(content);
    }
    return [];
  };

const customFieldContentToStrings =
  (context: ItemContentResolutionContext) =>
  (content: ProfileOverviewCardCustomFieldItemContent): string[] => {
    const customField = _.findWhere(
      context.profile.resumeData?.customFields ?? [],
      {
        clientCustomFieldId: content.customFieldId,
        type: content.customFieldType,
      },
    );
    if (customField?.type === 'inline-text' || customField?.type === 'text') {
      return customField.text ? [customField.text] : [];
    }
    if (customField?.type === 'enum') {
      const customFieldDefinition = _.findWhere(
        context.customFieldDefinitions,
        {
          id: content.customFieldId,
          type: content.customFieldType,
        },
      );
      if (customFieldDefinition?.type !== 'enum') {
        return customField.selected ?? [];
      }
      return customFieldDefinition.options
        .filter(({ id }) => _.includes(customField.selected ?? [], id))
        .map(({ title }) => getTranslatedText(context.lang, title));
    }
    return [];
  };

type ItemContentResolutionContext = {
  profile: SearchPoolProfile;
  customFieldDefinitions: CustomFieldDefinition[];
  lang: SupportedLanguage;
};
