import React, { useMemo, useState } from 'react';
import { Form } from 'semantic-ui-react';
import _ from 'underscore';

import { TranslatableText } from '@/types/text';
import GenericTextInput from '@/components/Common/GenericTextInput';
import GenericSelect from '@/components/Common/GenericSelect';
import { getRandomString, getTranslatedText } from '@/common';
import GenericButton from '@/components/Common/GenericButton';

import { useTranslation } from 'react-i18next';
import styles from '../../CustomDocumentTypesSettings.module.less';

export type DocumentType = {
  key: string;
  value: TranslatableText;
  category: string;
};

type Props = {
  onSubmit: (data: DocumentType) => void;
  setOpen: (open: boolean) => void;
  defaultValue?: DocumentType;
};

const CustomDocumentTypesSettingsForm: React.FC<Props> = ({
  onSubmit,
  setOpen,
  defaultValue,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState(getTranslatedText(defaultValue?.value));
  const [selectedCategory, setSelectedCategory] = useState(
    defaultValue?.category,
  );

  const categoryOptions = useMemo(() => {
    return [
      {
        value: 'profile-presentation',
        label: t(
          'reveal.parameters.documentTypesSettings.profile-presentation',
        ),
      },
      {
        value: 'anonymized-resume',
        label: t('reveal.parameters.documentTypesSettings.anonymized-resume'),
      },
      {
        value: 'contract',
        label: t('reveal.parameters.documentTypesSettings.contract'),
      },
      {
        value: 'positionDescription',
        label: t('reveal.parameters.documentTypesSettings.positionDescription'),
      },
      {
        value: 'progress-report',
        label: t('reveal.parameters.documentTypesSettings.progress-report'),
      },
      {
        value: 'other',
        label: t('reveal.parameters.documentTypesSettings.other'),
      },
    ];
  }, [t]);

  return (
    <div className={styles.modalForm}>
      <Form.Field className={styles.modalFormField}>
        <label>{t('reveal.parameters.documentTypesSettings.name')}</label>
        <GenericTextInput
          placeholder={t(
            'reveal.parameters.documentTypesSettings.namePlaceholder',
          )}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Field>
      <Form.Field className={styles.modalFormField}>
        <label>{t('reveal.parameters.documentTypesSettings.category')}</label>
        <GenericSelect
          value={categoryOptions.find(
            (option) => option.value === selectedCategory,
          )}
          options={categoryOptions}
          onChange={(selectedOption) => {
            setSelectedCategory(selectedOption?.value);
          }}
        />
      </Form.Field>
      <div className={styles.modalActions}>
        <GenericButton primacy='secondary' onClick={() => setOpen(false)}>
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          primacy='primary'
          onClick={() => {
            onSubmit({
              key: defaultValue?.key || getRandomString(12),
              value: { default: name },
              category: selectedCategory || '',
            });
          }}
        >
          {t('common.save')}
        </GenericButton>
      </div>
    </div>
  );
};

export default CustomDocumentTypesSettingsForm;
