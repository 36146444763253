import _ from 'underscore';
import React from 'react';

import useAtsFilterOptions from '@/graphql/hooks/clients/useClientAtsFiltersOptions';

import { ATS_LOGOS_BY_TYPE } from '@/common/reveal';
import AccordionFilter from '../components/AccordionFilter';
import getAdventureOptions from './getAdventureOptions';
import { countAllFilters } from '../Filters/utils';

// HACK
import '../GreenhouseFilters/GreenhouseFilters.css';
import GenericTagFilter from '../components/GenericTagFilter';

type OnUpdateFunction = (filters: any) => void;

interface AdventureFiltersProps {
  onUpdate: OnUpdateFunction;
  filters: any;
  projectId: string;
  clientId: string;
}
const AdventureFilters: React.FC<AdventureFiltersProps> = ({
  onUpdate,
  filters,
  projectId,
  clientId,
}) => {
  const { data: atsFilterOptionsData } = useAtsFilterOptions(clientId, {});
  const projectsWithFilterOptions =
    atsFilterOptionsData?.client?.revealProjects;
  const project = _.findWhere(projectsWithFilterOptions, { id: projectId });
  const hasAdventureConnector = !!_.findWhere(project?.connectors, {
    type: 'adventure',
  });

  if (!hasAdventureConnector) {
    return null;
  }

  const handleUpdateTags = ({ tagIds }: { tagIds?: string[] }) => {
    onUpdate({
      adventureFilters: {
        ...filters,
        tagIds: _.isEmpty(tagIds) ? null : tagIds,
      },
    });
  };

  const { tagOptions } = getAdventureOptions({
    projectId,
    projectsWithFilterOptions,
  });

  const atsLogo = ATS_LOGOS_BY_TYPE.adventure;

  return (
    <div className='greenhouse-filters'>
      <AccordionFilter
        title={
          <>
            <span className='filter-icon'>
              <img height='20px' alt='ats logo' src={atsLogo} />
            </span>
            Adventure
          </>
        }
        count={countAllFilters(filters)}
      >
        <div className='sub-title'>Qualifications</div>

        <GenericTagFilter
          categories={['qualification']}
          options={tagOptions}
          onChange={(
            e: React.SyntheticEvent,
            { value }: { value?: string[] },
          ) => handleUpdateTags({ tagIds: value })}
          value={filters?.tagIds || []}
        />
        <br />

        <div className='sub-title'>Habilitations</div>
        <GenericTagFilter
          categories={['certification']}
          options={tagOptions}
          onChange={(
            e: React.SyntheticEvent,
            { value }: { value?: string[] },
          ) => handleUpdateTags({ tagIds: value })}
          value={filters?.tagIds || []}
        />
        <br />

        <div className='sub-title'>Secteurs</div>
        <GenericTagFilter
          categories={['activity-sector']}
          options={tagOptions}
          onChange={(
            e: React.SyntheticEvent,
            { value }: { value?: string[] },
          ) => handleUpdateTags({ tagIds: value })}
          value={filters?.tagIds || []}
        />
        <br />

        <div className='sub-title'>Moyens de locomotion</div>
        <GenericTagFilter
          categories={['transportation-type']}
          options={tagOptions}
          onChange={(
            e: React.SyntheticEvent,
            { value }: { value?: string[] },
          ) => handleUpdateTags({ tagIds: value })}
          value={filters?.tagIds || []}
        />
        <br />
      </AccordionFilter>
    </div>
  );
};

export default AdventureFilters;
