import * as Sentry from '@sentry/browser';
import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Link, withRouter } from 'react-router-dom';
import { Form, Segment, Placeholder } from 'semantic-ui-react';
import _, { compose } from 'underscore';
import { useTranslation } from 'react-i18next';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import { useAddProfilesToMissionAndSequenceMutation } from '@/graphql/searchPoolProfiles';
import EmptyState from '@/revealComponents/EmptyState/EmptyState';
import GenericButton from '@/components/Common/GenericButton';
import { SequenceDropdownForJob } from '@/components/Reveal/Sequences/SequenceDropdown/SequenceDropdown';
import { AddProfileToSequenceContext } from '@/context/AddProfileToSequenceContext';
import { UNIPILE_TASK_TYPES } from '@/common/constants/taskTypes';
import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import InfoTooltip from '@/components/InfoTooltip';
import SidePanel from '../../../../../components/SidePanel';
import {
  CLIENT_SEQUENCES,
  useClientSequenceTemplates,
} from '../../../../../containers/Parameters/Sequences/queries';
import withClientUsers from '../../../../../hocs/clients/withClientUsers';
import contextToProps from '../../../../../hocs/contextToProps';
import withUserFromJWToken from '../../../../../hocs/users/withUserFromJWToken';
import SequencePreview from '../../../revealComponents/SequencePreview';

import './AddToSequenceSidebar.css';

const hasSender = (action) => {
  if (!action) {
    return false;
  }
  return !!(
    action.senderId ||
    action.message?.sender?.id ||
    action.message?.senderId
  );
};

const AddToSequenceSidebar = ({
  clientId,
  jobId,
  candidates,
  onClose,
  onShowNotification,
}) => {
  const { t } = useTranslation();
  const [selectedSequenceId, setSelectedSequenceId] = useState();
  const [autoSend, setAutoSend] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);

  const permissions = useClientPermissions(clientId).data?.client?.permissions;

  const variables = { clientId, filters: { activeOnly: true } };
  const clientSequences = useQuery(CLIENT_SEQUENCES, {
    variables,
    fetchPolicy: 'network-only',
  });
  const clientSequencesList = _.filter(
    clientSequences?.data?.client?.sequences,
    ({ isArchived }) => !isArchived,
  );

  const [addProfilesToMissionAndSequence] =
    useAddProfilesToMissionAndSequenceMutation();

  const selectedSequence = _.findWhere(clientSequencesList, {
    id: selectedSequenceId,
  });
  const disableFormSubmission = !selectedSequenceId && !canSubmit;

  const addProfileToSequenceContext = useContext(AddProfileToSequenceContext);
  const onSubmit = async () => {
    setSubmitting(true);
    try {
      const filteredCandidates = _.filter(
        candidates,
        (candidate) => !candidate.privacyState?.markedAsDoNotContact,
      );
      const profileIds = _.pluck(filteredCandidates, 'id');
      const refetchQueries = jobId ? ['getTasksCount'] : undefined;

      const { data } = await addProfilesToMissionAndSequence({
        variables: {
          searchPoolId: 'reveal',
          input: {
            missionId: jobId,
            sequenceId: selectedSequenceId,
            profileIds,
            shouldScheduleFirstSequenceAction: autoSend,
          },
        },
        sequence: selectedSequence,
        refetchQueries,
      });

      // In case we are displaying the rights modal
      if (!data) {
        return;
      }

      const results =
        data?.searchPool?.addProfilesToMissionAndSequence?.results || [];
      const successes = _.filter(results, ({ success }) => success);
      const errors = _.filter(results, ({ success }) => !success);

      // TODO: add a 'warning' / orange level for future use (instead of showing 2 notifications ?)
      if (!_.isEmpty(errors)) {
        onShowNotification({
          message: t('reveal.candidatesView.actionsBox.addToSequenceError', {
            count: errors.length > 1 ? 2 : 1,
            nb: errors.length,
          }),
          level: 'error',
        });
      }

      if (!_.isEmpty(successes)) {
        // TODO: custom notif with link
        onShowNotification({
          message: t('applySequenceDropdown.success', {
            count: successes.length > 1 ? 2 : 1,
            nb: successes.length,
          }),
          level: 'success',
        });
        // TODO: GO TO LASER FOCUS (?)
      } else {
        // setSubmitting(false);
        // onClose();
      }

      setSubmitting(false);
      onClose();
    } catch (e) {
      console.error(e);

      Sentry.captureException(e);
      onShowNotification({
        message: t('reveal.recommendedProfiles.sequence.addError'),
        level: 'error',
      });
      setSubmitting(false);
      onClose();
    }
  };

  const handleSequenceChange = (id) => {
    setSelectedSequenceId(id);
    if (
      !addProfileToSequenceContext.checkIfProfileCanBeAddedToSequence(
        _.findWhere(clientSequencesList, { id }),
      )
    ) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  };

  const { sequenceTemplates } = useClientSequenceTemplates({
    clientId,
    fetchPolicy: 'network-only',
  });
  const { defaultSequenceTemplates } = sequenceTemplates || {};

  const firstActionHasSender = hasSender(
    selectedSequence?.contactFlow?.sequences?.[0]?.actions?.[0],
  );

  if (!clientSequences?.loading && _.isEmpty(clientSequencesList)) {
    return (
      <SidePanel
        className='add-to-sequence-sidebar'
        title={`${t('reveal.sequenceSideBar.addToSequence')} ${t(
          'reveal.sequenceSideBar.candidate',
          {
            count: candidates?.length || 0,
          },
        )} ${t('reveal.sequenceSideBar.toSequence')}`}
        onClose={onClose}
      >
        <Segment>
          <EmptyState
            title={t('sequences.emptyState.title')}
            innerContent={t('sequences.emptyState.innerContent')}
            illustrationPath='/images/placeholders/sequencesEmptyState.svg'
            cta={
              <Link
                to={`/client/${clientId}/reveal/sequences/new-sequence-${
                  _.isEmpty(defaultSequenceTemplates) ? 'create' : 'preview'
                }`}
              >
                <GenericButton size='big'>
                  {t('sequences.emptyState.cta')}
                </GenericButton>
              </Link>
            }
          />
        </Segment>
      </SidePanel>
    );
  }

  const footerContent = (
    <GenericButton
      size='big'
      disabled={!canSubmit || disableFormSubmission || submitting}
      onClick={onSubmit}
    >
      {t('reveal.sequenceSideBar.addToSequenceButton')}
    </GenericButton>
  );

  const handleClose = () => {
    if (!submitting) {
      onClose();
    }
  };

  return (
    <SidePanel
      className='add-to-sequence-sidebar'
      title={`${t('reveal.sequenceSideBar.addToSequence')} ${t(
        'reveal.sequenceSideBar.candidate',
        {
          count: candidates?.length || 0,
        },
      )} ${t('reveal.sequenceSideBar.toSequence')}`}
      footerContent={footerContent}
      onClose={handleClose}
    >
      {!_.isEmpty(clientSequencesList) ? (
        <AddToSequenceForm
          clientId={clientId}
          clientSequencesList={clientSequencesList}
          selectedSequenceId={selectedSequenceId}
          setSelectedSequenceId={handleSequenceChange}
          jobId={jobId}
        />
      ) : (
        <>
          <Placeholder>
            <Placeholder.Header style={{ height: '14px', width: '100px' }}>
              <Placeholder.Line length='full' />
            </Placeholder.Header>
          </Placeholder>
          <Placeholder>
            <Placeholder.Header style={{ height: '38px' }}>
              <Placeholder.Line length='full' />
            </Placeholder.Header>
          </Placeholder>
        </>
      )}

      {selectedSequence && (
        <>
          <Segment className='sequence-preview-container'>
            <SequencePreview
              clientId={clientId}
              sequence={selectedSequence}
              alwaysExpanded
            />
          </Segment>
          {_.includes(
            UNIPILE_TASK_TYPES,
            selectedSequence.contactFlow?.sequences?.[0]?.actions?.[0]?.type,
          ) &&
            permissions.canScheduleAtBulkEnrollment && (
              <div className='auto-send-container'>
                <LabeledCheckbox
                  label={
                    firstActionHasSender ? (
                      <>
                        {t('reveal.sequenceSideBar.autoSend')}
                        <InfoTooltip
                          text={t('reveal.sequenceSideBar.autoSendTooltip')}
                        />
                      </>
                    ) : (
                      <>
                        {t('reveal.sequenceSideBar.autoSendDisabled')}
                        <InfoTooltip
                          text={t('reveal.sequenceSideBar.autoSendTooltip')}
                        />
                      </>
                    )
                  }
                  disabled={!firstActionHasSender}
                  checked={autoSend}
                  onClick={() => setAutoSend(!autoSend)}
                />
                {firstActionHasSender ? (
                  <p className='auto-send-warning'>
                    {t('reveal.sequenceSideBar.autoSendWarning')}
                  </p>
                ) : (
                  <p className='auto-send-warning'>
                    {t('reveal.sequenceSideBar.noSenderWarning')}
                  </p>
                )}
              </div>
            )}
        </>
      )}
    </SidePanel>
  );
};

const AddToSequenceForm = ({
  clientId,
  selectedSequenceId,
  setSelectedSequenceId,
  clientSequencesList,
  jobId,
}) => {
  const { t } = useTranslation();

  return (
    <Form className='tasks-form'>
      <Form.Field>
        <label>{t('reveal.sequenceSideBar.sequence')}</label>

        <div id='sequence-dropdown'>
          <SequenceDropdownForJob
            jobId={jobId}
            clientSequencesList={clientSequencesList}
            clientId={clientId}
            placeholder={t('reveal.modals.addToMission.sequencePlaceholder')}
            currentSequence={{
              sequenceId: selectedSequenceId,
            }}
            onSequenceSelected={setSelectedSequenceId}
            className='sidebar-sequence-popup'
          />
        </div>
      </Form.Field>
    </Form>
  );
};

export default compose(
  withRouter,
  withUserFromJWToken,
  withClientUsers,
  contextToProps,
)(AddToSequenceSidebar);
