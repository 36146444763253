import { gql, useMutation } from '@apollo/client';
import { ClientDocumentTypeResults } from './useAddClientDocumentType';

const DELETE_CLIENT_DOCUMENT_TYPE = gql`
  mutation DeleteClientDocumentType($key: String!) {
    deleteClientDocumentType(key: $key) {
      id
      clientDocumentTypes {
        key
        value {
          default
          fr
          en
        }
        category
      }
    }
  }
`;

interface DeleteClientDocumentTypeVariables {
  key: string;
}

const useDeleteClientDocumentType = () => {
  return useMutation<
    ClientDocumentTypeResults,
    DeleteClientDocumentTypeVariables
  >(DELETE_CLIENT_DOCUMENT_TYPE);
};

export default useDeleteClientDocumentType;
