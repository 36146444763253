import _ from 'underscore';
import React, { useState } from 'react';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

import {
  ATS_LOGOS_BY_TYPE,
  ATS_NAMES_BY_TYPE,
  LEVER,
  BOONDMANAGER,
} from '@/common/reveal';

import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import { ATSDataCard } from '@/containers/Profile/Resume/Card/ATSData/PluginATSData/PluginATSData';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

// FIXME: should not assume only one AtsDataItem
import { CandidateLastRefresh } from '@/containers/Profile/Resume/Card/ATSData/ATSData';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import styles from './ATSStatusBanner.module.less';
import AddToLeverJobModal from './Modals/AddToLeverJobModal';
import AddToBoondmanagerModal from './Modals/AddToBoondmanagerModal';
import AddToATSJobModal from './Modals/AddToATSJobModal';
import { useClientRevealConnector } from '../../../graphql/hooks/clients/useClientRevealProjects';

const ATS_WITH_CUSTOM_MODAL = [LEVER, BOONDMANAGER];

const AddToATSModal = ({
  open,
  atsId,
  clientId,
  profile,
  mainMissionId,
  onClose,
}) => {
  if (!open) {
    return null;
  }

  if (!_.contains(ATS_WITH_CUSTOM_MODAL, atsId)) {
    return (
      <AddToATSJobModal
        clientId={clientId}
        searchPoolId='reveal'
        mainMissionId={mainMissionId}
        profileId={profile?.id}
        onClose={onClose}
        ats={atsId}
        profileEmail={profile?.resumeData?.email}
      />
    );
  }

  if (atsId === LEVER) {
    return (
      <AddToLeverJobModal
        clientId={clientId}
        searchPoolId='reveal'
        mainMissionId={mainMissionId}
        profileId={profile?.id}
        onClose={onClose}
      />
    );
  }

  if (atsId === BOONDMANAGER) {
    return (
      <AddToBoondmanagerModal
        clientId={clientId}
        searchPoolId='reveal'
        mainMissionId={mainMissionId}
        profileId={profile?.id}
        onClose={onClose}
      />
    );
  }

  return null;
};

// Simple component to hide children if profile is already in ats
const MaybeProfileAlreadyInAts = ({ atsId, profile, children }) => {
  const atsDataItem = (profile?.resumeData?.sourceData || {})[atsId];
  const sourceCandidateId = atsDataItem?.sourceCandidateId;

  if (sourceCandidateId) {
    return null;
  }

  return children;
};

const ATSStatusBanner = ({ clientId, profile }) => {
  const { t } = useTranslation();
  const connector = useClientRevealConnector(clientId);
  const [atsModalOpen, setATSModalOpen] = useState(false);
  const ATSName = ATS_NAMES_BY_TYPE[connector?.type];
  const configurationParams = useMergedConfigurationParams();

  if (!ATSName) {
    return null;
  }

  if (configurationParams.shouldHideATSSynchronizationFeatures === 'true') {
    return null;
  }

  const mainMissionId = profile?.missionsInfo?.[0]?.missionId;
  const atsLogo = ATS_LOGOS_BY_TYPE[connector?.type];

  return (
    <MaybeProfileAlreadyInAts atsId={connector?.type} profile={profile}>
      <div
        className={classNames(styles['ats-status-banner'], styles.atsCardTitle)}
      >
        <h3>
          {atsLogo && <img height='24px' alt='ats logo' src={atsLogo} />}
          {ATS_NAMES_BY_TYPE[connector?.type] || 'ATS'}
        </h3>
        <span className={styles['last-sync']}>
          {t('reveal.profileModal.atsBanner.notSynced')}
        </span>
        <GenericButton
          primacy='secondary'
          className={styles['add-to-ats-button']}
          onClick={() => setATSModalOpen(true)}
        >
          <Plus />
          {t('reveal.profileModal.atsBanner.addToATS', { ATS: ATSName })}
        </GenericButton>
      </div>

      <AddToATSModal
        open={atsModalOpen}
        atsId={connector?.type}
        clientId={clientId}
        profile={profile}
        mainMissionId={mainMissionId}
        onClose={() => setATSModalOpen(false)}
      />
    </MaybeProfileAlreadyInAts>
  );
};

export const PluginAddToAtsCard = ({ clientId, profile }) => {
  const { t } = useTranslation();
  const { isMinimized } = useMinimizedView();
  const connector = useClientRevealConnector(clientId);
  const mainMissionId = profile?.missionsInfo?.[0]?.missionId;

  const [atsModalOpen, setATSModalOpen] = useState(false);

  if (!connector?.type || connector.type === 'custom') {
    return null;
  }

  return (
    <MaybeProfileAlreadyInAts atsId={connector?.type} profile={profile}>
      <div className={styles.atsCardTitle}>
        <ATSDataCard
          atsId={connector?.type}
          title={
            <div className={styles.addButtonContainer}>
              <CandidateLastRefresh />
              <GenericButton
                primacy='secondary'
                size='small'
                onClick={() => setATSModalOpen(true)}
                className={styles['add-to-ats-button']}
              >
                {!isMinimized && <Plus />}
                {t(
                  `reveal.profileModal.atsBanner.addToATSFromPlugin${
                    isMinimized ? 'Minimized' : ''
                  }`,
                )}
              </GenericButton>
            </div>
          }
        />
      </div>
      <AddToATSModal
        open={atsModalOpen}
        atsId={connector?.type}
        clientId={clientId}
        profile={profile}
        mainMissionId={mainMissionId}
        onClose={() => setATSModalOpen(false)}
      />
    </MaybeProfileAlreadyInAts>
  );
};

export default ATSStatusBanner;
