import React, { useCallback, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import SettingsLayout from '@/containers/SettingsLayout';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import Plus from '@/components/Reveal/Icons/Plus';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useClientCustomProfileSources from '@/graphql/hooks/clients/useClientCustomProfileSources';
import useAddClientCustomProfileSource from '@/graphql/hooks/clients/useAddClientCustomProfileSource';
import useUpdateClientCustomProfileSource from '@/graphql/hooks/clients/useUpdateClientCustomProfileSource';
import useDeleteClientCustomProfileSource from '@/graphql/hooks/clients/useDeleteClientCustomProfileSource';
import { TranslatableText } from '@/types/text';
import CustomProfileSourcesForm from './components/form/CustomProfileSourcesSettingsForm';
import styles from './CustomProfileSourcesSettings.module.less';
import CustomProfileSourcesTable from './components/table/CustomProfileSourcesTable';

type Props = {
  clientId: string;
};

type CustomProfileSource = {
  id: string;
  title: TranslatableText;
  isApplicationSourceOption: boolean;
};

const CustomProfileSources: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const [addCustomProfileSourceModalOpen, setAddCustomProfileSourceModalOpen] =
    useState(false);
  const [editionMode, setEditionMode] = useState<{
    isOpen: boolean;
    customProfileSource: CustomProfileSource | null;
  }>({
    isOpen: false,
    customProfileSource: null,
  });
  const [deletionMode, setDeletionMode] = useState<{
    isOpen: boolean;
    customProfileSourceId: string | null;
  }>({
    isOpen: false,
    customProfileSourceId: null,
  });
  const notification = useNotificationSystem();
  const [createCustomProfileSourceMutation] = useAddClientCustomProfileSource();
  const [updateCustomProfileSourceMutation] =
    useUpdateClientCustomProfileSource();
  const [deleteCustomProfileSourceMutation] =
    useDeleteClientCustomProfileSource();

  const { clientCustomProfileSources } = useClientCustomProfileSources({
    clientId,
  });

  const saveCustomProfileSource = useCallback(
    async (customProfileSource: CustomProfileSource) => {
      await createCustomProfileSourceMutation({
        variables: {
          customProfileSource: {
            ...customProfileSource,
          },
        },
      })
        .then(() => {
          notification.success(t('common.genericSuccess'));
          setAddCustomProfileSourceModalOpen(false);
        })
        .catch(() => {
          notification.error(t('common.genericError'));
        });
    },
    [createCustomProfileSourceMutation, notification, t],
  );

  const editCustomProfileSource = useCallback(
    async (customProfileSource: CustomProfileSource) => {
      try {
        await updateCustomProfileSourceMutation({
          variables: {
            customProfileSource: {
              ...customProfileSource,
            },
          },
        }).then(() => {
          setEditionMode({
            isOpen: false,
            customProfileSource: null,
          });
          notification.success(t('common.genericSuccess'));
        });
      } catch (error) {
        console.error(error);
        notification.error(t('common.genericError'));
      }
    },
    [updateCustomProfileSourceMutation, notification, t],
  );

  const deleteCustomProfileSource = useCallback(async () => {
    if (!deletionMode.customProfileSourceId) {
      return;
    }
    try {
      await deleteCustomProfileSourceMutation({
        variables: {
          id: deletionMode.customProfileSourceId,
        },
      }).then(() => {
        setDeletionMode({
          isOpen: false,
          customProfileSourceId: null,
        });
        notification.success(t('common.genericSuccess'));
      });
    } catch (error) {
      console.error(error);
      notification.error(t('common.genericError'));
    }
  }, [
    deleteCustomProfileSourceMutation,
    deletionMode.customProfileSourceId,
    notification,
    t,
  ]);

  const openEditMode = (customProfileSource: CustomProfileSource) => {
    setEditionMode({
      isOpen: true,
      customProfileSource,
    });
  };

  const openDeleteMode = (customProfileSourceId: string) => {
    setDeletionMode({
      isOpen: true,
      customProfileSourceId,
    });
  };

  return (
    <SettingsLayout
      currentPage='customProfileSources'
      clientId={clientId}
      className='settings-container'
      size='big'
    >
      <div className='settings'>
        <div className={styles.header}>
          <h1 className={styles.title}>
            {t('reveal.parameters.customProfileSourcesSettings.title')}
          </h1>
        </div>
        <h2>{t('settings.configuration')}</h2>
        <div className='section visible'>
          <div className={styles.description}>
            {t('reveal.parameters.customProfileSourcesSettings.description')}
          </div>
          <CustomProfileSourcesTable
            customSources={clientCustomProfileSources}
            onEdit={openEditMode}
            onDelete={openDeleteMode}
          />
          <div className='input-container'>
            <GenericButton
              onClick={() => setAddCustomProfileSourceModalOpen(true)}
            >
              <Plus />
              {t('reveal.parameters.customProfileSourcesSettings.create')}
            </GenericButton>
          </div>
        </div>
      </div>
      <GenericModal
        open={addCustomProfileSourceModalOpen}
        onOpen={() => setAddCustomProfileSourceModalOpen(true)}
        size='small'
      >
        <Modal.Header>
          {t(
            'reveal.parameters.customProfileSourcesSettings.creationModal.title',
          )}
        </Modal.Header>
        <Modal.Content>
          <CustomProfileSourcesForm
            onSubmit={saveCustomProfileSource}
            setOpen={setAddCustomProfileSourceModalOpen}
          />
        </Modal.Content>
      </GenericModal>
      <GenericModal
        open={editionMode.isOpen}
        onOpen={() =>
          setEditionMode((prevState) => ({ ...prevState, isOpen: false }))
        }
        size='small'
      >
        <Modal.Header>
          {t('reveal.parameters.customProfileSourcesSettings.edit')}
        </Modal.Header>
        <Modal.Content>
          <CustomProfileSourcesForm
            onSubmit={editCustomProfileSource}
            setOpen={(value) =>
              setEditionMode((prevState) => ({
                ...prevState,
                isOpen: value,
              }))
            }
            defaultValue={editionMode.customProfileSource || undefined}
          />
        </Modal.Content>
      </GenericModal>
      <ConfirmationModal
        header={t('reveal.parameters.customProfileSourcesSettings.delete')}
        submit={t('common.delete')}
        onSubmit={deleteCustomProfileSource}
        open={deletionMode.isOpen}
        onCancel={() =>
          setDeletionMode((prevState) => ({ ...prevState, isOpen: false }))
        }
      />
    </SettingsLayout>
  );
};

export default CustomProfileSources;
