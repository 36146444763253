import React, { useMemo, useState } from 'react';
import _ from 'underscore';
import { Loader } from 'semantic-ui-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import useAskProfileEnrichment from '@/graphql/hooks/searchPoolProfile/useAskProfileEnrichment';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import useUpdateProfileDataByEnrichment from '@/graphql/hooks/searchPoolProfile/useUpdateProfileDataByEnrichment';
import useUserEnrichmentIntegrations from '@/graphql/hooks/users/useUserEnrichmentIntegrations';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import GenericButton from '@/components/Common/GenericButton';

import styles from './enrichmentProvider.module.less';
import EnrichmentQuota, { getMinCreditsRemaining } from './enrichmentQuota';

type Props = {
  title: string;
  icon: string;
  provider: 'kaspr' | 'contactout' | 'rocketreach';
  profile: SearchPoolProfile;
};

const EnrichmentProvider: React.FC<Props> = ({
  title,
  icon,
  provider,
  profile,
}) => {
  const { t } = useTranslation();
  const notification = useNotificationSystem();
  const isPlugin = useIsPlugin();
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();
  const { isMinimized } = useMinimizedView();

  const [expand, setExpand] = useState(false);

  const { enrichmentIntegrations, loading: enrichmentIntegrationsLoading } =
    useUserEnrichmentIntegrations();
  const [askProfileEnrichmentMutation, { loading }] = useAskProfileEnrichment();
  const [updateProfileDataMutation] = useUpdateProfileDataByEnrichment();

  const { providerIntegration, isOwn } = useMemo(() => {
    if (!enrichmentIntegrations) {
      return {
        providerIntegration: null,
        isOwn: false,
      };
    }

    const ownProviderIntegration = _.find(
      enrichmentIntegrations,
      ({ type, useClientIntegration, status }) => {
        return (
          type === provider && !useClientIntegration && status === 'active'
        );
      },
    );

    const inheritedProviderIntegration = _.find(
      enrichmentIntegrations,
      ({ type, useClientIntegration, status }) => {
        return (
          type === provider &&
          useClientIntegration === true &&
          status === 'active'
        );
      },
    );

    // If there is no inherited we can obviously return the users own integration (or null)
    if (!inheritedProviderIntegration) {
      return {
        providerIntegration: ownProviderIntegration,
        isOwn: true,
      };
    }

    // User could have infinite quota
    if (!inheritedProviderIntegration.quota) {
      return {
        providerIntegration: inheritedProviderIntegration,
        isOwn: false,
      };
    }
    const { remainingCredits } = getMinCreditsRemaining(
      inheritedProviderIntegration,
    );
    if (remainingCredits <= 0) {
      return {
        providerIntegration: ownProviderIntegration,
        isOwn: true,
      };
    }

    return {
      providerIntegration: inheritedProviderIntegration,
      isOwn: false,
    };
  }, [enrichmentIntegrations, provider]);

  const profileHasEnrichedData = !!profile?.resumeData?.sourceData?.[provider];

  const enrichmentResults = _.last(
    profile?.resumeData?.sourceData?.[provider]?.enrichmentResults || [],
  );

  const displayTitle =
    !_.isEmpty(enrichmentResults?.emails) &&
    !_.isEmpty(enrichmentResults?.phoneNumbers);

  const enrichProfile = async () => {
    try {
      await askProfileEnrichmentMutation({
        variables: {
          provider,
          profileId: profile.id,
          searchPoolId: 'reveal',
        },
      });
      publishSubscriptionEvent('onProfileAskedEnrichmentResults', {
        id: profile.id,
      });
    } catch (error) {
      console.error(error);
      notification.error(t('reveal.contactout.enrichmentError'));
    }
  };

  const updateProfile = async ({
    email,
    phoneNumber,
  }: {
    email?: string;
    phoneNumber?: string;
  }) => {
    if (!email && !phoneNumber) {
      return;
    }
    try {
      await updateProfileDataMutation({
        variables: {
          profileId: profile.id,
          searchPoolId: 'reveal',
          input: {
            ...(email && { email }),
            ...(phoneNumber && { phoneNumber }),
          },
        },
      });
      notification.success(t('reveal.contactout.updateSuccess'));
    } catch (error) {
      console.error(error);
      notification.error(t('reveal.contactout.updateError'));
    }
  };

  const displayResults = useMemo(
    () => expand || !isMinimized,
    [expand, isMinimized],
  );

  if (enrichmentIntegrationsLoading || !providerIntegration) {
    return null;
  }

  if (providerIntegration.status !== 'active') {
    return null;
  }

  const { remainingCredits } = getMinCreditsRemaining(providerIntegration);
  const canAskForEnrichment =
    remainingCredits === null ? true : remainingCredits > 0;

  return (
    <div
      className={classNames(
        styles.contactout,
        isPlugin && styles.plugin,
        !profileHasEnrichedData && styles.flexBetween,
        isPlugin && displayTitle && styles.column,
        isMinimized && styles.minimized,
      )}
    >
      <div className={styles.iconWrapper}>
        <img
          className={styles.icon}
          alt='logo'
          src={icon}
          height={isMinimized ? 16 : 50}
        />
        <span className={styles.label}>{title}</span>
        <EnrichmentQuota integration={providerIntegration} isOwn={isOwn} />
      </div>
      {!enrichmentResults && !profileHasEnrichedData && (
        <GenericButton
          size='small'
          primacy='secondary'
          onClick={enrichProfile}
          disabled={loading || !canAskForEnrichment}
        >
          {t(`reveal.contactout.showResults${isMinimized ? 'Minimized' : ''}`)}
        </GenericButton>
      )}
      {enrichmentResults?.emails &&
        !_.isEmpty(enrichmentResults?.emails) &&
        displayResults && (
          <div className={styles.contactoutResult} style={{ maxWidth: '60%' }}>
            {displayTitle && <h5>Emails</h5>}
            <div className={styles.flexWrap}>
              {_.map(enrichmentResults.emails, (email, index) => (
                <span
                  key={`${index}-${email}`}
                  aria-hidden
                  className={styles.flexItem}
                  onClick={() => updateProfile({ email: email.value })}
                >
                  {email.value}
                </span>
              ))}
            </div>
          </div>
        )}
      {enrichmentResults?.phoneNumbers &&
        !_.isEmpty(enrichmentResults?.phoneNumbers) &&
        displayResults && (
          <div className={styles.contactoutResult}>
            {displayTitle && <h5> {t('reveal.contactout.phonesNumbers')}</h5>}
            <div className={styles.flexWrap}>
              {_.map(enrichmentResults.phoneNumbers, (phoneNumber, index) => (
                <span
                  key={`${index}-${phoneNumber}`}
                  aria-hidden
                  className={styles.flexItem}
                  onClick={() =>
                    updateProfile({ phoneNumber: phoneNumber.value })
                  }
                >
                  {phoneNumber.value}
                </span>
              ))}
            </div>
          </div>
        )}
      {_.isEmpty(enrichmentResults?.phoneNumbers) &&
      _.isEmpty(enrichmentResults?.emails) &&
      profileHasEnrichedData ? (
        <span className={styles.noResults}>
          {t(`reveal.contactout.emptyResults${isMinimized ? 'Minimized' : ''}`)}
        </span>
      ) : (
        profileHasEnrichedData &&
        isMinimized && (
          <button
            type='button'
            className={styles.expand}
            onClick={() => setExpand(!expand)}
          >
            {t('reveal.contactout.expand', {
              count: enrichmentResults?.emails?.length || 0,
            })}
            <i className={`ri-arrow-${expand ? 'up' : 'down'}-s-line`} />
          </button>
        )
      )}
      {loading && isMinimized && (
        <div className={styles.contactoutResult}>
          <Loader active inline='centered' size='mini' />
        </div>
      )}
    </div>
  );
};

export default EnrichmentProvider;
