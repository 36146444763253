import React from 'react';
import _ from 'underscore';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const GenericTagFilter = ({ categories, options, onChange, value }) => {
  const { t } = useTranslation();
  const categoryOptions = _.filter(options, (option) => {
    for (let i = 0; i < categories.length; i++) {
      if (option.value.startsWith(`${categories[i]}__`)) {
        return true;
      }
    }
    return false;
  });

  return (
    <Form.Dropdown
      fluid
      selection
      multiple
      search
      placeholder={t('common.select')}
      options={categoryOptions}
      onChange={onChange}
      value={value}
    />
  );
};

export default GenericTagFilter;
