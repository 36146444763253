import { TranslatableText } from '@/types/text';
import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const CLIENT_CUSTOM_PROFILE_SOURCES = gql`
  query CustomProfileSources($clientId: ID!) {
    client(id: $clientId) {
      id
      customProfileSources {
        id
        isApplicationSourceOption
        title {
          fr
          en
          default
        }
      }
    }
  }
`;

interface Data {
  client: {
    id: string;
    customProfileSources: CustomProfileSource[];
  };
}

export type CustomProfileSource = {
  id: string;
  isApplicationSourceOption: boolean;
  title: TranslatableText;
};

interface Variables {
  clientId: string;
}

interface Input extends Variables {
  queryOptions?: QueryHookOptions<Data, Variables>;
}

const useClientCustomProfileSources = ({
  clientId,
  queryOptions = {},
}: Input) => {
  const query = useQuery<Data, Variables>(CLIENT_CUSTOM_PROFILE_SOURCES, {
    ...queryOptions,
    variables: {
      clientId,
    },
  });
  const clientCustomProfileSources =
    query.data?.client?.customProfileSources || [];
  return { ...query, clientCustomProfileSources };
};

export default useClientCustomProfileSources;
