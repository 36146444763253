import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import _ from 'underscore';

import GenericTextInput from '@/components/Common/GenericTextInput';
import { getRandomString, getTranslatedText } from '@/common';
import GenericButton from '@/components/Common/GenericButton';

import { useTranslation } from 'react-i18next';
import { CustomProfileSource } from '@/graphql/hooks/clients/useClientCustomProfileSources';
import GenericToggle from '@/components/Common/GenericToggle';
import InfoTooltip from '@/components/InfoTooltip';
import styles from '../../CustomProfileSourcesSettings.module.less';

type Props = {
  onSubmit: (data: CustomProfileSource) => void;
  setOpen: (open: boolean) => void;
  defaultValue?: CustomProfileSource;
};

const CustomProfileSourcesSettingsForm: React.FC<Props> = ({
  onSubmit,
  setOpen,
  defaultValue,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState(getTranslatedText(defaultValue?.title));
  const [isApplicationSource, setIsApplicationSource] = useState(
    defaultValue?.isApplicationSourceOption,
  );

  return (
    <div className={styles.modalForm}>
      <Form.Field className={styles.modalFormField}>
        <label>
          {t('reveal.parameters.customProfileSourcesSettings.table.name')}
        </label>
        <GenericTextInput
          placeholder={t(
            'reveal.parameters.customProfileSourcesSettings.creationModal.namePlaceholder',
          )}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Field>
      <Form.Field className={styles.modalFormField}>
        <div className={styles.toggleLabelAndTooltip}>
          <GenericToggle
            isChecked={isApplicationSource}
            name='isApplicationOption'
            onChange={({ value }) => {
              setIsApplicationSource(value);
            }}
            label={t(
              'reveal.parameters.customProfileSourcesSettings.creationModal.toggleDescription',
            )}
          />
          <InfoTooltip
            text={t(
              'reveal.parameters.customProfileSourcesSettings.creationModal.toggleTooltip',
            )}
          />
        </div>
      </Form.Field>
      <div className={styles.modalActions}>
        <GenericButton primacy='secondary' onClick={() => setOpen(false)}>
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          primacy='primary'
          onClick={() => {
            onSubmit({
              id: defaultValue?.id || getRandomString(6).toLowerCase(),
              title: { default: name },
              isApplicationSourceOption: isApplicationSource || false,
            });
          }}
        >
          {t('common.save')}
        </GenericButton>
      </div>
    </div>
  );
};

export default CustomProfileSourcesSettingsForm;
