import { TranslatableText } from '@/types/text';
import { gql, useMutation } from '@apollo/client';
import { ClientCustomProfileSourceResults } from './useAddClientCustomProfileSource';

const UPDATE_CLIENT_CUSTOM_PROFILE_SOURCE = gql`
  mutation UpdateClientCustomProfileSource(
    $customProfileSource: UpdateClientCustomProfileSourceInput!
  ) {
    updateClientCustomProfileSource(customProfileSource: $customProfileSource) {
      id
      customProfileSources {
        id
        title {
          default
          fr
          en
        }
        isApplicationSourceOption
      }
    }
  }
`;

interface UpdateClientCustomProfileSourceVariables {
  customProfileSource: {
    id: string;
    title: TranslatableText;
    isApplicationSourceOption: boolean;
  };
}

const useUpdateClientCustomProfileSource = () => {
  return useMutation<
    ClientCustomProfileSourceResults,
    UpdateClientCustomProfileSourceVariables
  >(UPDATE_CLIENT_CUSTOM_PROFILE_SOURCE);
};

export default useUpdateClientCustomProfileSource;
