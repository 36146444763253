import _, { compose } from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import $ from 'jquery';

import MarketplacePrivacy from '@/containers/Parameters/MarketplacePrivacy/MarketplacePrivacy';
import ClientRevealIntegrations from '@/containers/Parameters/RevealIntegrations';
import RevealCompliance from '@/containers/Parameters/RevealCompliance/RevealCompliance';
import LegalDocumentation from '@/containers/Parameters/LegalDocumentation';
import ArchiveReasons from '@/containers/Parameters/ArchiveReasons/ArchiveReasons';
import SnippetsSettings from '@/containers/Parameters/SnippetsSettings';
import CompanySettingsPage from '@/containers/Parameters/CompanySettings';
import LinkedAccountsSettings from '@/containers/Parameters/LinkedinAccounts';
import { LockHistoryContextProvider } from '@/context/LockHistoryContext';
import ClientDataProviders from '@/containers/Parameters/DataProviders';
import SuperPipelineSettings from '@/containers/Parameters/SuperPipelineSettings';
import PermissionsSettings from '@/containers/Parameters/Permissions';
import CustomTasksSettings from '@/containers/Parameters/CustomTasks/CustomTasksSettings';
import CustomActivitiesSettings from '@/containers/Parameters/CustomActivities/CustomActivitiesSettings';
import ConfigurationParameters from '@/containers/Parameters/ConfigurationParameters/ConfigurationParameters';
import AutomationIntegration from '@/containers/Parameters/AutomationIntegration';
import TextingSettings from '@/containers/Parameters/Settings/Texting';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import CustomCompaniesStatesSettings from '@/containers/Parameters/CustomCompaniesStates';
import CustomDocumentTypes from '@/containers/Parameters/CustomDocumentTypes';

import withClient from '../hocs/clients/withClient';
import routerParamsToProps from '../hocs/routerParamsToProps';
import MainLayout from '../containers/MainLayout';
import CareerPages from '../containers/Parameters/CareerPages';
import ClientOffers from '../containers/Parameters/Offers';
import ClientCollaborators from '../containers/Parameters/Collaborators';
import ClientNewOffer from '../containers/Parameters/ClientNewOffer';
import ClientSettings from '../containers/Parameters/Settings';
import SendersSettings from '../containers/Parameters/Settings/SendersSettings';
import PluginSettings from '../containers/Parameters/PluginSettings';
import EmailSettings from '../containers/Parameters/Settings/EmailSettings';
import ClientIntegrations from '../containers/Parameters/Integrations';
import CalendarIntegrations from '../containers/Parameters/CalendarIntegrations';
import DepartmentsSettings from '../containers/Parameters/DepartmentsSettings';
import EvaluationFormsSettings from '../containers/Parameters/EvaluationForms';
// import ClientBilling from '../containers/Parameters/Billing';
import ClientSlackIntegration from '../containers/Parameters/SlackIntegration';
import NotificationSettings from '../containers/Parameters/NotificationSettings';
import RevealNotificationSettings from '../containers/Parameters/RevealNotifications/RevealNotificationsSettings';
import Analytics from '../containers/Parameters/Analytics';
import CustomFieldsSettings from '../containers/Parameters/CustomFieldsSettings';
import SSOSettings from '../containers/Parameters/SSOSettings';
import CustomProfileSources from '../containers/Parameters/CustomProfileSources';

import TemplatesView from './TemplatesView';

import './pagesview.css';

class ClientViewRoute extends React.PureComponent {
  static propTypes = {
    clientId: PropTypes.string.isRequired,
    pageId: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.prevScrollY = 0;
    this.updateHeaderStickiness = this.updateHeaderStickiness.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.updateHeaderStickiness);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateHeaderStickiness);
  }

  updateHeaderStickiness() {
    const H = 200;
    const reduce = (isHeaderSticky, prevY, newY) => {
      if (prevY === newY) {
        return isHeaderSticky;
      }
      if (newY <= 2) {
        return false;
      }
      if (Math.abs(newY - prevY) <= 3) {
        return isHeaderSticky;
      }
      if (newY > prevY && newY > H) {
        return false;
      }
      if (newY < prevY && newY > H) {
        return true;
      }
      return isHeaderSticky;
    };

    const oldMode = $('.parametersview').hasClass('header-is-hidden')
      ? 'header-is-hidden'
      : $('.parametersview').hasClass('header-is-sticky')
        ? 'header-is-sticky'
        : 'none';
    const oldIsHeaderSticky = oldMode === 'header-is-sticky';
    const newIsHeaderSticky = reduce(
      oldIsHeaderSticky,
      this.prevScrollY,
      window.scrollY,
    );

    const headerHeight = 60;

    const newMode = newIsHeaderSticky
      ? 'header-is-sticky'
      : window.scrollY > headerHeight
        ? 'header-is-hidden'
        : 'none';

    if (newMode !== oldMode) {
      _.each(['header-is-sticky', 'header-is-hidden'], (mode) => {
        if (newMode !== mode) {
          $('.parametersview').removeClass(mode);
        }
      });
      if (newMode !== 'none') {
        $('.parametersview').addClass(newMode);
      }
      if (newMode === 'header-is-sticky') {
        $('.parametersview').addClass('deploying-1');
        setTimeout(() => {
          $('.parametersview').removeClass('deploying-1');
          $('.parametersview').addClass('deploying-2');
          setTimeout(() => {
            $('.parametersview').removeClass('deploying-2');
            $('.parametersview').addClass('deploying-3');
            setTimeout(() => {
              $('.parametersview').removeClass('deploying-3');
            }, 33);
          }, 33);
        }, 33);
      }
    }

    this.prevScrollY = window.scrollY;
  }

  render() {
    const {
      clientId,
      loading,
      pageId,
      history,
      client,
      // applicationMode
    } = this.props;

    const pagesList = [
      'offers',
      'jobs',
      'new-offer',
      'new-job',
      'templates',
      'sequences',
      'settings',
      'company-settings',
      'linkedin',
      'pages',
      'email',
      'departments',
      'customFields',
      'snippets',
      'custom-document-types',
      'senders',
      'calendar',
      'evaluationForms',
      'collaborators',
      'users',
      'analytics',
      'reports',
      'terms',
      'legal',
      'integrations',
      'crm-integrations',
      'billing',
      'plugin',
      'slackIntegration',
      'notifications',
      'crm-notifications',
      'careerPage',
      'sso',
      'compliance',
      'privacy',
      'archive-reasons',
      'providers',
      'pipeline',
      'permissions',
      'custom-tasks',
      'custom-activities',
      'customization-parameters',
      'automation-integrations',
      'phone',
      'inbox',
      'companies-states',
      'custom-profile-sources',
    ];

    const settingsPageList = [
      'settings',
      'company-settings',
      'email',
      'senders',
      'linkedin',
      'pages',
      'calendar',
      'collaborators',
      'users',
      'terms',
      'legal',
      'integrations',
      'crm-integrations',
      'billing',
      'plugin',
      'slackIntegration',
      'notifications',
      'crm-notifications',
      'departments',
      'customFields',
      'snippets',
      'custom-document-types',
      'evaluationForms',
      'careerPage',
      'sso',
      'compliance',
      'privacy',
      'archive-reasons',
      'providers',
      'pipeline',
      'permissions',
      'custom-tasks',
      'custom-activities',
      'customization-parameters',
      'automation-integrations',
      'phone',
      'companies-states',
      'custom-profile-sources',
    ];

    const isSettingsPage = (id) => {
      return _.contains(settingsPageList, id);
    };

    if (_.indexOf(pagesList, pageId) === -1) {
      return <Redirect to={`/client/${clientId}/dashboard`} />;
    }

    if (loading) {
      return null;
    }

    return (
      <MainLayout
        pageId={pageId}
        clientId={clientId}
        hideFooter={isSettingsPage(pageId)}
      >
        <Switch>
          <Route
            exact
            path='/client/:clientId/templates'
            component={TemplatesView}
          />
          <Route exact path='/client/:clientId/offers'>
            <Redirect to={`/client/${clientId}/jobs`} replace />
          </Route>
          <Route exact path='/client/:clientId/jobs'>
            <ClientOffers clientId={clientId} history={history} />
          </Route>
          <Route exact path='/client/:clientId/new-offer'>
            <Redirect to={`/client/${clientId}/new-job`} replace />
          </Route>
          <Route exact path='/client/:clientId/new-job'>
            <ClientNewOffer clientId={clientId} history={history} />
          </Route>

          <Route exact path='/client/:clientId/collaborators'>
            <Redirect to={`/client/${clientId}/users`} replace />
          </Route>
          <Route
            exact
            path='/client/:clientId/users'
            component={ClientCollaborators}
          />

          <Route exact path='/client/:clientId/analytics'>
            <Redirect to={`/client/${clientId}/reports`} replace />
          </Route>
          <Route exact path='/client/:clientId/reports'>
            <Analytics clientId={clientId} />
          </Route>
          {/* <Route
            exact
            path='/client/:clientId/billing'
            component={ClientBilling}
          /> */}
          <Route exact path='/client/:clientId/settings'>
            <ClientSettings clientId={clientId} />
          </Route>

          <Route path='/client/:clientId/company-settings'>
            <LockHistoryContextProvider>
              <CompanySettingsPage clientId={clientId} />
            </LockHistoryContextProvider>
          </Route>
          <Route exact path='/client/:clientId/email'>
            <EmailSettings clientId={clientId} />
          </Route>
          <Route exact path='/client/:clientId/senders'>
            <SendersSettings clientId={clientId} />
          </Route>
          <Route exact path='/client/:clientId/phone'>
            <TextingSettings clientId={clientId} />
          </Route>
          <Route path='/client/:clientId/calendar'>
            <CalendarIntegrations clientId={clientId} />
          </Route>
          {client?.permissions?.careerPage && (
            <Route path='/client/:clientId/careerPage'>
              <CareerPages clientId={clientId} />
            </Route>
          )}
          <Route path='/client/:clientId/departments'>
            <DepartmentsSettings clientId={clientId} />
          </Route>
          <Route path='/client/:clientId/evaluationForms'>
            <EvaluationFormsSettings clientId={clientId} />
          </Route>
          <Route path='/client/:clientId/customFields'>
            <CustomFieldsSettings clientId={clientId} />
          </Route>
          <Route path='/client/:clientId/snippets'>
            <SnippetsSettings clientId={clientId} />
          </Route>
          <Route path='/client/:clientId/custom-document-types'>
            <CustomDocumentTypes clientId={clientId} />
          </Route>
          <Route path='/client/:clientId/custom-profile-sources'>
            <CustomProfileSources clientId={clientId} />
          </Route>
          <Route path='/client/:clientId/integrations'>
            <ClientIntegrations clientId={clientId} />
          </Route>
          <Route path='/client/:clientId/crm-integrations'>
            <ClientRevealIntegrations clientId={clientId} />
          </Route>
          <Route path='/client/:clientId/providers'>
            <ClientDataProviders clientId={clientId} />
          </Route>
          <Route path='/client/:clientId/slackIntegration'>
            <ClientSlackIntegration clientId={clientId} />
          </Route>
          <Route exact path='/client/:clientId/notifications'>
            <NotificationSettings clientId={clientId} />
          </Route>
          <Route path='/client/:clientId/crm-notifications'>
            <RevealNotificationSettings clientId={clientId} />
          </Route>
          <Route exact path='/client/:clientId/plugin'>
            <PluginSettings clientId={clientId} />
          </Route>
          <Route exact path='/client/:clientId/linkedin'>
            <LinkedAccountsSettings clientId={clientId} />
          </Route>
          <Route exact path='/client/:clientId/legal'>
            <LegalDocumentation clientId={clientId} />
          </Route>
          <Route exact path='/client/:clientId/sso'>
            <SSOSettings clientId={clientId} />
          </Route>
          <Route exact path='/client/:clientId/compliance'>
            <RevealCompliance clientId={clientId} />
          </Route>
          <Route exact path='/client/:clientId/privacy'>
            <MarketplacePrivacy clientId={clientId} />
          </Route>
          <Route exact path='/client/:clientId/archive-reasons'>
            <ArchiveReasons clientId={clientId} />
          </Route>
          <Route
            path='/client/:clientId/pipeline/:missionCategory'
            render={({ match }) => (
              <MissionCategoryContext.Provider
                value={match.params.missionCategory}
              >
                <SuperPipelineSettings key={match.params.missionCategory} />
              </MissionCategoryContext.Provider>
            )}
          />
          <Route exact path='/client/:clientId/pipeline'>
            <Redirect to={`/client/${clientId}/pipeline/recruiting`} />
          </Route>
          <Route exact path='/client/:clientId/permissions'>
            <PermissionsSettings clientId={clientId} />
          </Route>
          <Route exact path='/client/:clientId/customization-parameters'>
            <ConfigurationParameters />
          </Route>
          <Route exact path='/client/:clientId/custom-tasks'>
            <CustomTasksSettings clientId={clientId} />
          </Route>
          <Route exact path='/client/:clientId/custom-activities'>
            <CustomActivitiesSettings clientId={clientId} />
          </Route>
          <Route exact path='/client/:clientId/automation-integrations'>
            <AutomationIntegration />
          </Route>
          <Route exact path='/client/:clientId/companies-states'>
            <CustomCompaniesStatesSettings />
          </Route>
          <Route
            path='/404'
            render={() => <Redirect to={`/client/${clientId}/signin`} />}
          />
          <Redirect from='*' to='/404' />
        </Switch>
      </MainLayout>
    );
  }
}

export default compose(routerParamsToProps, withClient)(ClientViewRoute);
