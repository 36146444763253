import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const GENERATE_DOCUMENT_FROM_PROFILES = gql`
  mutation GenerateProfileListDocument(
    $profileIds: [ID!]!
    $jobId: String
    $searchPoolId: ID!
    $templateId: String
  ) {
    searchPool(id: $searchPoolId) {
      generateProfileListDocument(
        profileIds: $profileIds
        jobId: $jobId
        templateId: $templateId
      ) {
        creationDate
        name
        type
        file {
          content
        }
      }
    }
  }
`;

type Data = {
  searchPool: {
    generateProfileListDocument: {
      creationDate: string;
      name: string;
      type: string;
      file: {
        content: string;
      };
    };
  } | null;
};

type Variables = {
  profileIds: string[];
  jobId?: string;
  searchPoolId: string;
  templateId?: string;
};

type Input = {
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

function useGenerateWordDocumentFromProfiles({
  mutationOptions = {},
}: Input = {}) {
  const [mutation, result] = useMutation<Data, Variables>(
    GENERATE_DOCUMENT_FROM_PROFILES,
    mutationOptions,
  );
  return [mutation, result] as const;
}

export default useGenerateWordDocumentFromProfiles;
