import _ from 'underscore';
import React, { FC } from 'react';

import useIsPlugin from '@/hooks/common/useIsPlugin';
import { useClientRevealConnector } from '@/graphql/hooks/clients/useClientRevealProjects';

import { ATStype, SUPPORTED_ATS_TYPES } from '@/common/reveal';
import { getCandidateAtsURL } from '@/common/reveal/candidateAtsURL';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import { AtsData } from '@/common/reveal/SourceData.type';

import useMinimizedView from '@/hooks/ui/useMinimizedView';
import ClassicATSSingleData from './ATSData/ATSData';
import PluginATSData from './ATSData/PluginATSData/PluginATSData';
import HumanskillsATSSingleData from './ATSData/HumanskillsATSData';
import CBATSSingleData from './ATSData/CBATSData';

interface SingleATSDataProps {
  data?: AtsData;
  profile: SearchPoolProfile;
  clientId: string;
}

const SingleATSData: FC<SingleATSDataProps> = ({ data, clientId, profile }) => {
  const connector = useClientRevealConnector(clientId, {
    pollInterval: 60_000,
  }); // 1 minute poll interval
  const isPlugin = useIsPlugin();

  // Pre-calculate timestamps as int to get the max of both
  const candidatesLastRefreshTimestamp = Number.parseInt(
    connector?.synchronizationStatus.candidatesLastRefreshTimestamp || '0',
    10,
  );
  const profileLastRefreshTimestamp = Number.parseInt(
    profile?.lastATSRefreshTimestamp || '0',
    10,
  );

  if (!data) {
    return null;
  }

  const referredAtsType: ATStype =
    data.type === 'generic' && data.asAtsType ? data.asAtsType : data.type;

  const atsType: ATStype =
    referredAtsType === 'boondmanager_sales' ? 'boondmanager' : referredAtsType;

  const parameters =
    referredAtsType === 'boondmanager_sales'
      ? [{ key: 'mode', value: 'sales' }]
      : [];

  const { applications } = data || {};
  // const lastActionTimestamp = safeDateParse(lastActivityDate);
  const lastRefreshTimestamp = Math.max(
    candidatesLastRefreshTimestamp,
    profileLastRefreshTimestamp,
  ).toString();

  const candidateURL = getCandidateAtsURL({
    atsDataItem: data,
    accountId: connector?.accountId,
  });

  const isHiredApplication = (application: any) =>
    (application?.status?.sourceStatusId || '').toLowerCase().includes('hired');

  const isHired = _.some(applications || [], isHiredApplication);

  const filteredApplications = _.filter(
    applications || [],
    (application) => !isHired || isHiredApplication(application),
  );

  let Component = (clientId ?? '').includes('humanskills')
    ? HumanskillsATSSingleData
    : clientId === 'chantal-baudron'
    ? CBATSSingleData
    : ClassicATSSingleData;
  if (isPlugin) {
    Component = PluginATSData;
  }

  return (
    <Component
      clientId={clientId}
      atsId={atsType}
      atsData={data}
      parameters={parameters}
      isHired={isHired}
      filteredApplications={filteredApplications}
      lastRefreshTimestamp={lastRefreshTimestamp}
      candidateURL={candidateURL}
      profile={profile}
    />
  );
};

interface ATSDataProps {
  profile: SearchPoolProfile;
  clientId: string;
}

const ATSData: FC<ATSDataProps> = ({ clientId, profile }) => {
  const isPlugin = useIsPlugin();
  const { isMinimized } = useMinimizedView();
  const connector = useClientRevealConnector(clientId);

  const sourceData = profile?.resumeData?.sourceData;
  if (!sourceData) {
    return null;
  }

  if (isPlugin) {
    if (!connector || isMinimized) {
      return null;
    }

    const currentAtsData = sourceData?.[connector.type];
    return (
      <SingleATSData
        data={currentAtsData}
        profile={profile}
        clientId={clientId}
      />
    );
  }

  const atsTypes = _.filter(
    SUPPORTED_ATS_TYPES,
    (type) => !!sourceData?.[type],
  );

  return (
    <>
      {_.map(_.sortBy(atsTypes), (type, index) => (
        <SingleATSData
          data={sourceData[type]}
          profile={profile}
          clientId={clientId}
          key={index}
        />
      ))}
    </>
  );
};

export default ATSData;
