import { TranslatableText } from '@/types/text';
import { gql, useMutation } from '@apollo/client';
import { ClientDocumentTypeResults } from './useAddClientDocumentType';

const UPDATE_CLIENT_DOCUMENT_TYPE = gql`
  mutation UpdateClientDocumentType($documentType: AddClientDocumentTypeInput) {
    updateClientDocumentType(documentType: $documentType) {
      id
      clientDocumentTypes {
        key
        value {
          default
          fr
          en
        }
        category
      }
    }
  }
`;

interface UpdateClientDocumentTypeVariables {
  documentType: {
    key: string;
    value: TranslatableText;
    category: string;
  };
}

const useUpdateClientDocumentType = () => {
  return useMutation<
    ClientDocumentTypeResults,
    UpdateClientDocumentTypeVariables
  >(UPDATE_CLIENT_DOCUMENT_TYPE);
};

export default useUpdateClientDocumentType;
