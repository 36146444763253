import React, { useCallback, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import SettingsLayout from '@/containers/SettingsLayout';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import Plus from '@/components/Reveal/Icons/Plus';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';

import { useClientDocumentTypes } from '@/graphql/hooks/clients/useClientDocumentTypes';
import useAddClientDocumentType from '@/graphql/hooks/clients/useAddClientDocumentType';
import useUpdateClientDocumentType from '@/graphql/hooks/clients/useUpdateClientDocumentType';
import useDeleteClientDocumentType from '@/graphql/hooks/clients/useDeleteClientDocumentType';
import CustomDocumentTypesForm, {
  DocumentType,
} from './components/form/CustomDocumentTypesSettingsForm';
import styles from './CustomDocumentTypesSettings.module.less';
import CustomDocumentTypesTable from './components/table/CustomDocumentTypesTable';

type Props = {
  clientId: string;
};

const CustomDocumentTypes: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const [addDocumentTypeModalOpen, setAddDocumentTypeModalOpen] =
    useState(false);
  const [editionMode, setEditionMode] = useState<{
    isOpen: boolean;
    documentType: DocumentType | null;
  }>({
    isOpen: false,
    documentType: null,
  });
  const [deletionMode, setDeletionMode] = useState<{
    isOpen: boolean;
    documentTypeKey: string | null;
  }>({
    isOpen: false,
    documentTypeKey: null,
  });
  const notification = useNotificationSystem();
  const [createDocumentTypeMutation] = useAddClientDocumentType();
  const [updateDocumentTypeMutation] = useUpdateClientDocumentType();
  const [deleteDocumentTypeMutation] = useDeleteClientDocumentType();

  const { clientDocumentTypes } = useClientDocumentTypes();

  const saveDocumentType = useCallback(
    async (documentType: DocumentType) => {
      await createDocumentTypeMutation({
        variables: {
          documentType: {
            ...documentType,
          },
        },
      })
        .then(() => {
          notification.success(t('common.genericSuccess'));
          setAddDocumentTypeModalOpen(false);
        })
        .catch(() => {
          notification.error(t('common.genericError'));
        });
    },
    [createDocumentTypeMutation, notification, t],
  );

  const editDocumentType = useCallback(
    async (documentType: DocumentType) => {
      try {
        await updateDocumentTypeMutation({
          variables: {
            documentType: {
              ...documentType,
            },
          },
        }).then(() => {
          setEditionMode({
            isOpen: false,
            documentType: null,
          });
          notification.success(t('common.genericSuccess'));
        });
      } catch (error) {
        console.error(error);
        notification.error(t('common.genericError'));
      }
    },
    [updateDocumentTypeMutation, notification, t],
  );

  const deleteDocumentType = useCallback(async () => {
    if (!deletionMode.documentTypeKey) {
      return;
    }
    try {
      await deleteDocumentTypeMutation({
        variables: {
          key: deletionMode.documentTypeKey,
        },
      }).then(() => {
        setDeletionMode({
          isOpen: false,
          documentTypeKey: null,
        });
        notification.success(t('common.genericSuccess'));
      });
    } catch (error) {
      console.error(error);
      notification.error(t('common.genericError'));
    }
  }, [
    deleteDocumentTypeMutation,
    deletionMode.documentTypeKey,
    notification,
    t,
  ]);

  const openEditMode = (documentType: DocumentType) => {
    setEditionMode({
      isOpen: true,
      documentType,
    });
  };

  const openDeleteMode = (documentTypeKey: string) => {
    setDeletionMode({
      isOpen: true,
      documentTypeKey,
    });
  };

  return (
    <SettingsLayout
      currentPage='documentTypes'
      clientId={clientId}
      className='settings-container'
      size='big'
    >
      <div className='settings'>
        <div className={styles.header}>
          <h1 className={styles.title}>
            {t('reveal.parameters.documentTypesSettings.title')}
          </h1>
        </div>
        <h2>{t('settings.configuration')}</h2>
        <div className='section visible'>
          <div className={styles.description}>
            {t('reveal.parameters.documentTypesSettings.description')}
          </div>
          <CustomDocumentTypesTable
            documentTypes={clientDocumentTypes}
            onEdit={openEditMode}
            onDelete={openDeleteMode}
          />
          <div className='input-container'>
            <GenericButton onClick={() => setAddDocumentTypeModalOpen(true)}>
              <Plus />
              {t('reveal.parameters.documentTypesSettings.create')}
            </GenericButton>
          </div>
        </div>
      </div>
      <GenericModal
        open={addDocumentTypeModalOpen}
        onOpen={() => setAddDocumentTypeModalOpen(true)}
        size='small'
      >
        <Modal.Header>
          {t('reveal.parameters.documentTypesSettings.creationModal.title')}
        </Modal.Header>
        <Modal.Content>
          <CustomDocumentTypesForm
            onSubmit={saveDocumentType}
            setOpen={setAddDocumentTypeModalOpen}
          />
        </Modal.Content>
      </GenericModal>
      <GenericModal
        open={editionMode.isOpen}
        onOpen={() =>
          setEditionMode((prevState) => ({ ...prevState, isOpen: false }))
        }
        size='small'
      >
        <Modal.Header>
          {t('reveal.parameters.documentTypesSettings.edit')}
        </Modal.Header>
        <Modal.Content>
          <CustomDocumentTypesForm
            onSubmit={editDocumentType}
            setOpen={(value) =>
              setEditionMode((prevState) => ({
                ...prevState,
                isOpen: value,
              }))
            }
            defaultValue={editionMode.documentType || undefined}
          />
        </Modal.Content>
      </GenericModal>
      <ConfirmationModal
        header={t('reveal.parameters.documentTypesSettings.delete')}
        submit={t('common.delete')}
        onSubmit={deleteDocumentType}
        open={deletionMode.isOpen}
        onCancel={() =>
          setDeletionMode((prevState) => ({ ...prevState, isOpen: false }))
        }
      />
    </SettingsLayout>
  );
};

export default CustomDocumentTypes;
