import React from 'react';
import classNames from 'classnames';
import _ from 'underscore';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './CustomDocumentTypesTable.module.less';
import { getTranslatedText } from '../../../../../common/index';
import { DocumentType } from '../form/CustomDocumentTypesSettingsForm';

interface Props {
  documentTypes: DocumentType[] | undefined;
  onEdit: (documentType: DocumentType) => void;
  onDelete: (documentTypeId: string) => void;
}

const CustomDocumentTypesSettingsTable: React.FC<Props> = ({
  documentTypes,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();

  if (_.isEmpty(documentTypes) || !documentTypes?.length) {
    return null;
  }

  return (
    <Table basic className={styles.table}>
      <Table.Header className={styles.header}>
        <Table.Row className={styles.headerRow}>
          <Table.HeaderCell className={styles.th}>
            <span>
              {t('reveal.parameters.documentTypesSettings.table.name')}
            </span>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.th}>
            <span>
              {t('reveal.parameters.documentTypesSettings.table.category')}
            </span>
          </Table.HeaderCell>
          <Table.HeaderCell collapsing className={styles.th} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(documentTypes, (documentType) => (
          <Table.Row key={documentType.key} className={styles.row}>
            <Table.Cell textAlign='left' className={styles.name}>
              <span>{getTranslatedText(documentType.value)}</span>
            </Table.Cell>
            <Table.Cell textAlign='left' className={styles.subtype}>
              <span>
                {documentType.category
                  ? t(
                      `reveal.parameters.documentTypesSettings.${documentType.category}`,
                    )
                  : '-'}
              </span>
            </Table.Cell>

            <Table.Cell className={styles.actions}>
              <i
                className={classNames('ri-pencil-line ri-lg', styles.icon)}
                onClick={() => onEdit(documentType)}
              />
              <i
                className={classNames('ri-delete-bin-line ri-lg', styles.icon)}
                onClick={() => onDelete(documentType.key)}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default CustomDocumentTypesSettingsTable;
