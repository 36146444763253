import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import {
  useClientProfileCustomFields,
  CustomFieldDefinition,
} from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { getTranslatedText } from '@/common';
import CustomFieldForm, {
  TypeOptionType,
} from '@/components/CustomFields/CustomFieldForm';
import useClientId from '@/hooks/router/useClientId';
import {
  CustomFieldOption,
  CustomFieldNumber,
} from '@/components/CustomFields/ProfileCustomFieldsPreview';
import useModal from '@/hooks/useModal';
import { formatToMonthDYyyy } from '@/common/dates';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import useCSVCreateColumnCustomField from './useCSVCreateColumnCustomField';
import {
  getInitialForColumn,
  getFloatValue,
  getIntegerValue,
  getDateFormat,
  getDateValue,
} from './analysis.helpers';
import { CSVLines, ColumnsMapping, CSVLine, ColumnMapping } from './helpers';
import { getCustomFieldsForColumnMapping } from './customFields';
import getDropdownOptions from './dropdownOptions';

import ColumnMappingSelector from './ColumnMappingSelector';
import ModalColumnDefaultValue from './ModalColumnDefaultValue';

import './CSVPreview.css';

type CSVPreviewProps = {
  headerOptionsForm: React.ReactNode;
  headers: CSVLine | null;
  rows: CSVLines;
  columnsMapping: ColumnsMapping;
  updateColumnMapping: (
    index: number,
    columnMapping: ColumnMapping | null,
  ) => void;
  previewLength?: number;
};

function convertValueToMapping(
  value: string | boolean | number | unknown[] | undefined,
): ColumnMapping | null {
  if (!value || Array.isArray(value) || typeof value !== 'string') {
    return null;
  }

  if (!value.startsWith('custom-field:')) {
    return {
      id: value,
      type: 'classic-field',
    };
  }
  return {
    id: value.replace('custom-field:', ''),
    type: 'custom-field',
  };
}
function getColumnMappingValue(columnMapping: ColumnMapping | null) {
  if (!columnMapping) return null;

  if (columnMapping.type === 'custom-field') {
    return `custom-field:${columnMapping.id}`;
  }
  return columnMapping.id;
}
const CSVPreview = ({
  headerOptionsForm,
  headers,
  rows,
  columnsMapping,
  updateColumnMapping,
  previewLength = 10,
}: CSVPreviewProps): JSX.Element => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const customFieldsQuery = useClientProfileCustomFields(clientId);
  const customFields = getCustomFieldsForColumnMapping(
    customFieldsQuery.profileCustomFields,
  );
  const configurationParams = useMergedConfigurationParams();
  // Column options
  const dropdownOptions = getDropdownOptions(t);
  const customFieldOptions = customFields.map((customField) => ({
    ...customField,
    value: `custom-field:${customField.id}`,
    text: `${getTranslatedText(customField.title)}`,
  }));
  const allOptions = [...dropdownOptions, ...customFieldOptions];
  const getActiveItemText = (mapping: ColumnMapping | null) => {
    const value = getColumnMappingValue(mapping);
    const item = allOptions.find((option) => option.value === value);
    return item ? item.text : null;
  };

  const onChangeColumnMapping =
    (index: number) =>
      (
        event: React.MouseEvent<HTMLElement> | React.SyntheticEvent<HTMLElement>,
        { value }: { value?: string | boolean | number | unknown[] },
      ) => {
        const mapping = convertValueToMapping(value);
        updateColumnMapping(index, mapping);
      };

  const modalSetColumnDefaultValue = useModal<{
    columnIndex: number;
    mapping: ColumnMapping;
  }>();
  const { modalCreateCustomField, onSubmitNewCustomField } =
    useCSVCreateColumnCustomField({
      customFields,
      updateColumnMapping,
    });
  const columnInitialField = getInitialForColumn(rows, {
    columnIndex: modalCreateCustomField?.params?.columnIndex,
  });

  const dataPreview = rows.slice(0, previewLength);
  const totalLinesCount = rows.length || 0;
  const allowedCustomFieldTypes: TypeOptionType[] = modalCreateCustomField
    .params?.isNewColumn
    ? ['inline-text', 'text', 'enum', 'integer', 'float']
    : ['inline-text', 'text', 'enum', 'integer', 'float', 'day'];

  const { mapping: modalDefaultValueMapping = null } =
    modalSetColumnDefaultValue.params || {};
  const modalDefaultValueCustomField =
    modalDefaultValueMapping?.type === 'custom-field'
      ? customFields.find(
        (customField) => customField.id === modalDefaultValueMapping.id,
      )
      : null;
  return (
    <>
      <div className='csv-preview'>
        <div className='csv-preview__header'>{headerOptionsForm}</div>
        <div className='scroll-container'>
          <div>
            <span className='body-header'>
              {previewLength < totalLinesCount
                ? t('CSVImport.previewDescription', {
                  previewLength,
                  totalCount: totalLinesCount,
                })
                : t('CSVImport.previewDescriptionAll')}
            </span>
            <span className='body-muted'>
              {t('CSVImport.mappingInstructionsReveal')}
            </span>
          </div>
          <div className='scroll-container-content'>
            <div className='dropdown-container'>
              {_.map(columnsMapping, (mapping, index) => (
                <ColumnMappingSelector
                  key={`${columnsMapping[index]}-${index}`}
                  selected={getColumnMappingValue(mapping)}
                  index={index}
                  columnsMapping={columnsMapping}
                  selectedText={getActiveItemText(mapping)}
                  onSelect={onChangeColumnMapping(index)}
                  dropdownOptions={dropdownOptions}
                  hideCustomFields={
                    configurationParams?.shouldHideCompanyConfigurationSettings ===
                    'true'
                  }
                  customFieldOptions={customFieldOptions}
                  onAddField={() =>
                    modalCreateCustomField.open({
                      params: { columnIndex: index },
                    })
                  }
                />
              ))}
              <ColumnMappingSelector
                selected={null}
                index={columnsMapping.length}
                columnsMapping={columnsMapping}
                selectedText={null}
                onSelect={(_event: unknown, { value }) => {
                  const mapping = convertValueToMapping(value);
                  if (!mapping) return;
                  modalSetColumnDefaultValue.open({
                    params: { columnIndex: columnsMapping.length, mapping },
                  });
                }}
                dropdownOptions={dropdownOptions}
                hideCustomFields={
                  configurationParams?.shouldHideCompanyConfigurationSettings ===
                  'true'
                }
                customFieldOptions={customFieldOptions}
                onAddField={() => {
                  modalCreateCustomField.open({
                    params: {
                      columnIndex: columnsMapping.length,
                      isNewColumn: true,
                    },
                  });
                }}
                isNewColumnSelector
              />
            </div>

            {!_.isEmpty(dataPreview) && (
              <DataPreview
                headerLine={headers}
                rows={dataPreview}
                customFields={
                  configurationParams?.shouldHideCompanyConfigurationSettings ===
                    'true'
                    ? []
                    : customFields
                }
                columnsMapping={columnsMapping}
              />
            )}
          </div>
        </div>
      </div>
      {modalCreateCustomField.isOpen && (
        <CustomFieldForm
          initial={columnInitialField?.initial}
          initialEnumOptions={columnInitialField?.initialEnumOptions}
          open={modalCreateCustomField.isOpen}
          closeModal={modalCreateCustomField.close}
          onSubmit={onSubmitNewCustomField}
          customFields={customFields}
          shouldProposeDefaultValue={modalCreateCustomField.params?.isNewColumn}
          onlyCustomFieldTypes={allowedCustomFieldTypes}
        />
      )}
      {modalSetColumnDefaultValue.isOpen && modalDefaultValueMapping && (
        <ModalColumnDefaultValue
          open={modalSetColumnDefaultValue.isOpen}
          closeModal={modalSetColumnDefaultValue.close}
          customFieldDefinition={modalDefaultValueCustomField}
          onSubmit={(defaultValue: string | string[]) => {
            const { params } = modalSetColumnDefaultValue;
            if (!params) return;
            updateColumnMapping(params.columnIndex, {
              ...params.mapping,
              defaultValue,
            });
          }}
        />
      )}
    </>
  );
};

type EmptyPreviewPlaceholderProps = {
  headerLine: CSVLine | null;
  rows: CSVLines;
  columnsMapping: ColumnsMapping;
  customFields: CustomFieldDefinition[];
};
function DataPreview({
  headerLine,
  rows,
  columnsMapping,
  customFields,
}: EmptyPreviewPlaceholderProps) {
  const columnMappers = React.useMemo(
    () =>
      columnsMapping.map((columnMapping, index) => {
        if (!columnMapping) return null;
        if (columnMapping.type !== 'custom-field') {
          return (cell: string) => cell ?? columnMapping.defaultValue;
        }
        const customFieldId = columnMapping.id;
        const customField = _.findWhere(customFields, { id: customFieldId });
        const { defaultValue } = columnMapping;
        if (!customField) return null;
        if (customField.type === 'enum') {
          const dv = Array.isArray(defaultValue)
            ? defaultValue
            : [defaultValue];
          const options = Object.fromEntries(
            customField.options.map((option) => [
              option.id,
              getTranslatedText(option.title),
            ]),
          );
          return (cell: string) => {
            const values = cell ? [cell] : dv;
            return _.compact(values).map((value) => (
              <CustomFieldOption key={value}>
                {options[value] || value}
              </CustomFieldOption>
            ));
          };
        }
        if (customField.type === 'float') {
          return (cell: string) => {
            const value = getFloatValue(cell) || defaultValue;
            return (
              <CustomFieldNumber originalValue={cell}>
                {value}
              </CustomFieldNumber>
            );
          };
        }
        if (customField.type === 'integer') {
          return (cell: string) => {
            const value = getIntegerValue(cell) || defaultValue;

            return (
              <CustomFieldNumber originalValue={cell}>
                {value}
              </CustomFieldNumber>
            );
          };
        }
        if (customField.type === 'day') {
          const column = rows.map((row) => row[index]);
          const dateFormat =
            index >= rows.length
              ? getDateFormat([String(defaultValue)])
              : getDateFormat(column);
          return (cell: string) => {
            const value = getDateValue(
              cell || String(defaultValue),
              dateFormat,
            );
            return (
              <CustomFieldNumber originalValue={cell}>
                {formatToMonthDYyyy(new Date(value || ''))}
              </CustomFieldNumber>
            );
          };
        }
        return (cell: string) => {
          const value = cell || defaultValue;
          return value;
        };
      }),
    // eslint-disable-next-line
    [columnsMapping, customFields],
  );
  const getMapper = (cellIndex: number) =>
    cellIndex < columnMappers.length ? columnMappers[cellIndex] : undefined;
  return (
    <Table data-testid='csv-preview-table'>
      <Table.Body>
        {headerLine && (
          <Table.Row className='header-row'>
            {_.map(headerLine, (cell, cellIndex) => (
              <Table.Cell key={`header-${cellIndex}`}>
                {`(${cell})` || ''}
              </Table.Cell>
            ))}
          </Table.Row>
        )}
        {_.compact(
          _.map(rows, (row, index) => {
            if (_.isEmpty(row)) {
              return null;
            }
            return (
              <Table.Row key={index}>
                {_.map(columnsMapping, (mapping, cellIndex) => {
                  const cell = row[cellIndex];
                  const cellText = getMapper(cellIndex)?.(cell) || cell || '';
                  return (
                    <Table.Cell
                      className='csv-cell'
                      key={`${index}-${cellIndex}`}
                      title={cellText as string}
                    >
                      {cellText}
                    </Table.Cell>
                  );
                })}
                <Table.Cell />
              </Table.Row>
            );
          }),
        )}
      </Table.Body>
    </Table>
  );
}

export default CSVPreview;
