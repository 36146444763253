import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import _ from 'underscore';
import { ColumnsMapping } from './helpers';
import { DropdownOption } from './dropdownOptions';

import styles from './CSVPreview.module.less';

type ColumnsMappingSelectorProps = {
  columnsMapping: ColumnsMapping;
  index: number;
  selected: string | null;
  selectedText: string | null;
  onSelect: (
    event: React.MouseEvent<HTMLElement> | React.SyntheticEvent<HTMLElement>,
    { value }: { value?: string | boolean | number | unknown[] },
  ) => void;
  dropdownOptions: DropdownOption[];
  hideCustomFields?: boolean;
  customFieldOptions: DropdownOption[];
  onAddField: () => void;
  isNewColumnSelector?: boolean;
};
export default function ColumnMappingSelector(
  props: ColumnsMappingSelectorProps,
): JSX.Element {
  const {
    columnsMapping,
    index,
    selected,
    selectedText,
    onSelect,
    dropdownOptions,
    hideCustomFields = false,
    onAddField,
    customFieldOptions,
    isNewColumnSelector = false,
  } = props;
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = React.useState('');

  const isFieldMapped = (
    type: 'classic-field' | 'custom-field',
    id: string,
  ) => {
    return columnsMapping.some(
      (column) => column?.type === type && column.id === id,
    );
  };

  const availableColumnOptions = dropdownOptions
    .filter(
      ({ value }) =>
        value && value !== selected && !isFieldMapped('classic-field', value),
    )
    .filter(({ value }) => {
      return (
        !isNewColumnSelector ||
        (value && ['position', 'company', 'location'].includes(value))
      );
    })
    .filter(({ value }) => {
      return searchQuery ? value?.toLowerCase().includes(searchQuery) : true;
    });
  const availableCustomFieldOptions = customFieldOptions
    .filter(
      ({ value }) =>
        value && value !== selected && !isFieldMapped('custom-field', value),
    )
    .filter(({ value }) => {
      return searchQuery ? value?.toLowerCase().includes(searchQuery) : true;
    });
  return (
    <div className={styles.dropdownContainer}>
      {isNewColumnSelector &&
        _.isEmpty(selected) &&
        _.isEmpty(selectedText) && (
          <div className={styles.customPlaceholder}>
            <i className='ri-add-line ri-lg' />
            {t('CSVImport.addColumn')}
          </div>
        )}
      <Dropdown
        className='column-dropdown hs-dropdown-ellipsis selection'
        placeholder={t('CSVImport.selectField')}
        clearable
        fluid
        search
        onChange={onSelect}
        value={selected || ''}
        text={selectedText || undefined}
        searchQuery={searchQuery}
        onSearchChange={(event, { searchQuery: newSearchQuery }) => {
          setSearchQuery(newSearchQuery);
        }}
        searchInput={{ 'data-form-type': 'other' }}
      >
        <Dropdown.Menu>
          {availableColumnOptions.map(({ value, text, icon }) => (
            <Dropdown.Item
              key={`${value}-${index}`}
              value={value || undefined}
              text={text}
              icon={icon}
              active={value === selected}
              onClick={onSelect}
              className={styles.dropdownOption}
            />
          ))}
          <Dropdown.Divider className={styles.dropdownDivider} />
          {!hideCustomFields && (
            <>
              <Dropdown.Header className={styles.dropdownCustomFieldsHeader}>
                {t('CSVImport.dropdownOptions.customFields')}
                <button
                  type='button'
                  className={styles.dropdownCustomFieldsAdd}
                  onClick={onAddField}
                >
                  <i className='ri-add-line' />
                  <span>{t('CSVImport.dropdownOptions.addCustomField')}</span>
                </button>
              </Dropdown.Header>
              {availableCustomFieldOptions.map((cf) => (
                <Dropdown.Item
                  key={cf.id}
                  value={cf.value || undefined}
                  text={cf.text}
                  active={selected === cf.value}
                  onClick={onSelect}
                  className={styles.dropdownOption}
                />
              ))}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
